<template>
  <div></div>
</template>

<script>

export default {
  name: 'app-logout',
  mounted () {
    this.$store.dispatch('security/AUTH_LOGOUT').then(() => {
      this.$router.push({ path: '/protokoll' }).catch(() => {
        // Der Push liefert einen Error, weil /protokoll mittels Auth geschützt ist
        // der Router redirected dann automatisch auf /security/login
      })
    })
  }
}
</script>
